<template>
  <div>
    <div class="subtitle-2 mb-3">Help Text Markdown Editor</div>
    <v-md-editor
        v-model="text"
        :html="text"
        left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code"
        right-toolbar="preview fullscreen"
        height="400px">
    </v-md-editor>
    <fieldsTds
      style="display: none;"
      :fieldAttrRow="fieldAttrRow"
      :fieldAttributes="{
        ...fieldAttributes,
        type: 'textarea',
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="text"
      :valueLine="valueLine"
      id="helpText"
    ></fieldsTds>
  </div>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
//import {getRandomInt} from "@/js/helper";

VMdEditor.lang.use('en-US', enUS);

// highlightjs
import hljs from 'highlight.js';

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

export default {
  components: { fieldsTds,VMdEditor },
  data: function () {
    return {
      text: this.valueLine[this.field],
    };
  },
  props: [
    "field",
    "fieldAttributes",
    "fieldAttrInput",
    "value",
    "valueLine",
    "isValid",
    "result",
    "fieldAttrRow",
  ],
  created() {
   this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  computed: {
   markedValue(){
      return typeof this.valueLine[this.field]!=='undefined' ? this.valueLine[this.field]: "";
    },
  },
};
</script>